// Created By MK

// React Imports
import { useContext } from "react"

// MUI Imports
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import useMediaQuery from '@mui/material/useMediaQuery';

// Custom Imports
import { DialogContext } from "../../../Contexts"
import CustomBtn from "../../../CustomComponents/CutomButton"

// Style Imports
import landingStyle from "./landing.style";

const SinglePlatform = () => {
    const iPhone13Mini = useMediaQuery("(width: 360px) and (height: 780px)");
    const iPhone14 = useMediaQuery("(width: 390px) and (height: 844px)");
    const iPhone14Pro = useMediaQuery("(width: 393px) and (height: 852px)");
    const iPadMini = useMediaQuery("(width: 608px) and (height: 926px)");
    const iPad = useMediaQuery("(width: 575px) and (height: 767px)");
    const iPadPro = useMediaQuery("(width: 581px) and (height: 832px)");
    const iPadAir4 = useMediaQuery("(width: 573px) and (height: 824px)");
    const iPadAir5 = useMediaQuery("(width: 820px) and (height: 1180px)");
    const pixel7Pro = useMediaQuery("(width: 480px) and (height: 1040px)");
    const pixel2XL = useMediaQuery("(width: 375px) and (height: 750px)");
    const pixel2 = useMediaQuery("(width: 375px) and (height: 667px)");
    const pixel4 = useMediaQuery("(width: 370px) and (height: 781px)");
    const pixel4a = useMediaQuery("(width: 372px) and (height: 805px)");
    const pixel5 = useMediaQuery("(width: 376px) and (height: 815px)");
    const iPhoneSE = useMediaQuery("(width: 375px) and (height: 667px)");
    const iPhone11Pro = useMediaQuery("(width: 375px) and (height: 812px)");
    const iPhone12Mini = useMediaQuery("(width: 379px) and (height: 820px)");
    const surfaceProX = useMediaQuery("(width: 575px) and (height: 862px)");
    const galaxyTabS7 = useMediaQuery("(width: 526px) and (height: 842px)");
    const galaxyS20 = useMediaQuery("(width: 370px) and (height: 822px)");
    const galaxyNeo20Ultra = useMediaQuery("(width: 390px) and (height: 830px)");
    const galaxyNote20 = useMediaQuery("(width: 371px) and (height: 824px)");
    const galaxyA50 = useMediaQuery("(width: 372px) and (height: 800px)");

    const features = [
        "Accept Online Orders",
        "Manage Online Menu",
        "Secure Payment Processing",
        "Comprehensive Revenue Tracking",
    ];

    // Hooks
    const { setOpen } = useContext(DialogContext);

    return (
        <Box sx={{ position: 'relative' }}>
            <Grid container sx={landingStyle.spMain}>
                <Grid item xs={12} md={6} sx={landingStyle.spG1}>
                    <Box>
                        <Typography sx={landingStyle.spG1Txt1}>
                            Single Platform
                        </Typography>
                        <Typography sx={landingStyle.spG1Txt2}>
                            A single Online Ordering System to manage all your orders
                        </Typography>
                        <Typography sx={landingStyle.spG1Txt3}>
                            Handle all your restaurant's orders from one centralized Online Ordering System. Simplify operations, streamline efficiency, and enhance customer satisfaction with ease.
                        </Typography>
                        <Box sx={{ mb: 4 }}>
                            {features.map((feature, index) => (
                                <Box key={index} sx={landingStyle.ftG2Bx1}>
                                    <Box component={'img'} src='/images/checkbox.svg' sx={landingStyle.ftG2CheckBx} />
                                    <Typography sx={landingStyle.ftG2CheckTxt}>
                                        {feature}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={landingStyle.cmnBtnBx}>
                            <CustomBtn
                                title='Get Started Free!'
                                sx={landingStyle.cmnBtn}
                                onClick={() => {
                                    setOpen(true)
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={landingStyle.spG2}>
                    <Box component={'img'} src='/images/singlePlatform.png' sx={landingStyle.spG2Img} />
                </Grid>
            </Grid>

            <Box component={'img'} src='/images/arrow.svg' sx={landingStyle.spArrow} />
            <Box component={'img'} src='/images/arrowUp.svg' sx={{
                position: 'absolute',
                top: { xs: (iPhone14 || iPhone14Pro || iPhone12Mini || galaxyNeo20Ultra) ? '49%' : (iPhone13Mini || iPhoneSE || iPhone11Pro || galaxyS20 || galaxyNote20 || galaxyA50 || pixel2XL || pixel2 || pixel4 || pixel4a || pixel5) ? '51%' : (iPad || iPadPro || iPadAir4 || surfaceProX) ? '40%' : (pixel7Pro) ? '43%' : (galaxyTabS7) ? '41%' : '46%', sm: iPadAir5 ? '39%' : iPadMini ? '47%' : '36%' },
                left: { xs: iPhone14Pro ? '10%' : (pixel7Pro) ? '14%' : (iPad || iPadPro || iPadAir4 || surfaceProX || galaxyTabS7) ? '20%' : '10%', sm: iPadAir5 ? '24%' : '25%' },
                zIndex: 0,
                display: { xs: 'block', md: 'none' }
            }} />
        </Box >
    )
}

export default SinglePlatform