// Created By MK

// MUI Imports
import Button from "@mui/material/Button"
import ColorsHelper from "../../Helpers/Colors"

const CustomBtn = (props: any) => {
    const { sx, title, fullWidth, onClick } = props

    return (
        <Button
            fullWidth={fullWidth ?? false}
            sx={{
                bgcolor: ColorsHelper.orangeClr,
                color: ColorsHelper.whiteClr,
                textTransform: 'capitalize',
                borderRadius: 6,
                padding: '0.625rem 1.25rem',
                fontSize: '1rem',
                fontWeight: '600',
                fontFamily: 'Roobert, sans-serif !important',
                border: `0.063rem solid ${ColorsHelper.orangeClr}`,
                '&:hover': {
                    bgcolor: ColorsHelper.whiteClr,
                    color: ColorsHelper.orangeClr,
                },
                ...sx
            }}
            onClick={onClick}
        >{title}</Button>
    )
}

export default CustomBtn