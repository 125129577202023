// Created By MK

// React Imports
import { useState } from "react";

// Third Party Imports
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

// Components Imports
import Homepage from "./Pages/Homepage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import TermsNCondition from "./Pages/TermsNCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ScrollToTop from "./CustomComponents/ScrollToTop";
import { DialogContext } from "./Contexts";
import CustomDialog from "./Components/CustomDialog";
import { Toaster } from "react-hot-toast";

const App = () => {
    // States
    const [open, setOpen] = useState<boolean>(false);

    return (
        <HelmetProvider>
            <Toaster />
            <Router>
                <DialogContext.Provider value={{ open, setOpen }}>
                    <ScrollToTop />
                    <Header />
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/termsOfService" element={<TermsNCondition />} />
                        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                    </Routes>
                    <Footer />
                    <CustomDialog open={open} setOpen={setOpen} />
                </DialogContext.Provider>
            </Router>
        </HelmetProvider>
    );
};

export default App;