// Created By MK

// React Imports
import { useState, useEffect } from 'react';

// MUI Imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Third Party Imports
import * as yup from 'yup'
import { useFormik } from 'formik'
import toast from 'react-hot-toast';

// Helper Imports
import ColorsHelper from '../../Helpers/Colors';
import Consthelper from '../../Helpers/Constants';
import countryCodes from '../../Assets/CountryCodes';
import UrlHelper from '../../Helpers/Url';

// Custom Imports
import CustomBtn from '../../CustomComponents/CutomButton';
import CustomTextfield from '../../CustomComponents/CustomTextfield';
import CustomMobileInput from '../../CustomComponents/CustomMobileInput';

// Style Imports
import dialogStyle from './CustomDlg.style';

const CustomDialog = ({ open, setOpen }: any) => {

    // States
    const [stdCode, setStdCode] = useState<{ value: string; label: string, key: string }>({ value: '1', label: '+1', key: 'CA' })

    const initialValues = {
        full_name: '',
        email: '',
        country_code: 1,
        country_code_initials: 'CA',
        mobile_no: '',
    }

    const validationSchema = yup.object().shape({
        full_name: yup.string().required('Full name is required'),
        email: yup
            .string()
            .email('Enter a valid email')
            .matches(Consthelper.emailRegex, 'Enter valid email')
            .required('Email is required'),
        mobile_no: yup.string().trim()
            .min(13, 'Enter valid phone number')
            .max(16, 'Enter valid phone number')
            .matches(Consthelper.mobileNoRegex, 'Contact must be 10 digits')
            .test("test-mobNo", 'Enter valid phone number', (val, context) => {
                if (val && val !== null && val !== undefined) {
                    const moNo: String = val ?? "";
                    const onlyNums = moNo.replace(/[^0-9]/g, "");
                    return Consthelper.mobileNRegex.test(onlyNums);
                } else return false;
            })
            .required('Phone number is required'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const passData: any = {
                country_code: Number(values.country_code),
                phone: values?.mobile_no.replace(/\D/g, ''),
                country_code_initials: values.country_code_initials,
                customer_email: values?.email.trim(),
                customer_name: values?.full_name.trim()
            };

            const formData = new FormData();
            for (const key in passData) {
                if (passData.hasOwnProperty(key)) {
                    formData.append(key, passData[key]);
                }
            }

            const saveSettings = async (formData: any) => {
                const response = await fetch(`${UrlHelper?.serverUrl}/sendMenuonlineInquiry`, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                return await response.json();
            };

            toast.promise(
                saveSettings(formData),
                {
                    loading: 'Sending...',
                    success: <b>Thank you, someone will send you a quote momentarily 😊</b>,
                    error: <b>Something went wrong..!</b>,
                }
            )
            handleClose();
        }
    })

    useEffect(() => {
        if (open) {
            const fetchData = async () => {
                try {
                    const response = await fetch("https://ipapi.co/json");
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const res = await response.json();
                    const code: any = countryCodes.find((item: any) => item.key === res.country_code && item.label === res.country_calling_code);
                    formik.setFieldValue('country_code', code?.value)
                    formik.setFieldValue('country_code_initials', code?.key)
                    setStdCode({ value: code?.value, label: code?.label, key: code?.key });
                } catch (error: any) {
                    console.log('Error::::', error)
                }
            };

            fetchData();
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
        formik.resetForm()
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: dialogStyle.dlgMain,
            }}
        >
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ ...dialogStyle.dlgIconBtn, color: (theme) => theme.palette.grey[500] }}
                >
                    <Box
                        component={'img'}
                        src='/images/close.svg'
                    />
                </IconButton>
                <Box sx={dialogStyle.dlgCntMain}>
                    <Box
                        component={'img'}
                        src='/images/iPadMockup.png'
                        sx={dialogStyle.dlgImg}
                    />
                    <Box sx={dialogStyle.dlgRBx}>
                        <Typography sx={dialogStyle.dlgTxt}>
                            Get Started for Free!
                        </Typography>
                        <Typography sx={dialogStyle.dlgTxt2}>
                            Experience Seamless Order Management Today.
                        </Typography>

                        <Typography sx={dialogStyle.lbl}>Your full name</Typography>
                        <CustomTextfield
                            placeholder='John Doe'
                            formSx={{
                                mb: 2
                            }}
                            id={'full_name'}
                            name='full_name'
                            value={formik.values.full_name}
                            onChange={(e: any) => {
                                const val = e.target.value
                                !Consthelper.spaceRegex.test(val) &&
                                    formik.setFieldValue('full_name', val.replace(Consthelper.alphabetRegex, ''))
                            }}
                            error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                            helperText={formik.touched.full_name && formik.errors.full_name}
                        />
                        <Typography sx={dialogStyle.lbl}>Email</Typography>
                        <CustomTextfield
                            placeholder='johndoe@email.com'
                            formSx={{
                                mb: 2
                            }}
                            id={'email'}
                            name='email'
                            value={formik.values.email}
                            onChange={(e: any) => {
                                const val = e.target.value
                                !Consthelper.spaceRegex.test(val) && formik.setFieldValue('email', val)
                            }}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />

                        <Typography sx={dialogStyle.lbl}>Phone Number</Typography>
                        <CustomMobileInput
                            formSx={{
                                mb: 4
                            }}
                            fullWidth
                            id={'mobile_no'}
                            name='mobile_no'
                            placeholder={'Enter phone number'}
                            type='text'
                            value={formik.values.mobile_no}
                            onChange={(e: any) => {
                                formik.handleChange(e);
                            }}
                            error={formik.touched.mobile_no && Boolean(formik.errors.mobile_no)}
                            helperText={formik.touched.mobile_no && formik.errors.mobile_no}
                            stdcode={stdCode}
                            stdCodeChange={(val: { value: string; label: string; key: string }) => {
                                formik.setFieldValue('country_code', val?.value)
                                formik.setFieldValue('country_code_initials', val?.key)
                                setStdCode(val)
                            }}
                        />
                        <CustomBtn
                            title='Get Started Free'
                            fullWidth={true}
                            sx={{
                                borderRadius: 1
                            }}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CustomDialog