// Created By MK
import ColorsHelper from "../../Helpers/Colors";

const dialogStyle = {
  dlgMain: {
    maxHeight: "100%",
    width: "100%",
    borderRadius: 3,
    padding: { xs: 0, sm: 2 },
  },
  dlgIconBtn: {
    p: 2,
    position: "absolute",
    right: 20,
    top: 20,
  },
  dlgCntMain: {
    display: "flex",
    justifyContent: { xs: "center", md: "space-between" },
    alignItems: "center",
    mt: 5,
  },
  dlgImg: {
    width: "100%",
    height: "100%",
    maxWidth: { md: "31.25rem" },
    display: { xs: "none", md: "block" },
  },
  dlgRBx: {
    width: "100%",
    maxWidth: { xs: "31.25rem", md: "auto" },
  },
  dlgTxt: {
    fontWeight: "700",
    fontSize: { xs: "1.875rem", sm: "2.188rem" },
    lineHeight: "3.125rem",
  },
  dlgTxt2: {
    fontWeight: "400",
    fontSize: "0.938rem",
    mb: 2,
  },
  lbl: {
    fontWeight: "500",
    fontSize: { xs: "1.125rem", sm: "1.375rem" },
    lineHeight: "3.125rem",
    color: ColorsHelper.blackClr,
  },
};
export default dialogStyle;
