import { FormLabel, OutlinedInput } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import ColorsHelper from '../../Helpers/Colors'


const CustomTextfield = (props: any) => {

    const { placeholder, formSx, onChange } = props

    return (
        <FormControl fullWidth error={props.error} sx={props?.formSx}>
            <OutlinedInput
                fullWidth
                placeholder={placeholder ?? ''}
                id="company_name"
                onChange={onChange}
                name={props.name}
                value={props.value}
                sx={{
                    borderRadius: "0.313rem",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: ColorsHelper.lightClr4,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: ColorsHelper.orangeClr,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: ColorsHelper.orangeClr,
                        borderWidth: 2,
                    },
                    fontFamily: 'Roobert, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '1rem'
                }}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    )
}

export default CustomTextfield