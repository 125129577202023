// Created By MK
import ColorsHelper from "../../Helpers/Colors";

const termsStyle = {
  tosMain: { mt: 8.9, bgcolor: ColorsHelper.lightClr2 },
  tosMainBx: {
    bgcolor: ColorsHelper.lightBlack,
    height: "11.375rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tosMainTxt: {
    color: ColorsHelper.whiteClr,
    fontFamily: "Roobert",
    fontWeight: "600",
    fontSize: { xs: "40px", sm: "48px" },
    lineHeight: "57.6px",
    textAlign: "center",
  },
  tosBx: { py: 10, px: { xs: 5, sm: 15, md: 26 } },
};

export default termsStyle;
