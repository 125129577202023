// MUI Imports
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Third Party Imports
import { Helmet } from 'react-helmet-async';

// Helper Imports
import ColorsHelper from "../../Helpers/Colors"

// Style Imports
import termsStyle from "./TermsNCondition.style";

const TermsNCondition = () => {

    return (
        <>
            <Helmet>
                <title>Terms of Service | Menu Online</title>
                <meta name="description" content="Menu Online Terms of Service" />
            </Helmet>
            <Box sx={termsStyle.tosMain}>
                <Box sx={termsStyle.tosMainBx}>
                    <Typography sx={termsStyle.tosMainTxt}>Terms of Service</Typography>
                </Box>

                <Box sx={termsStyle.tosBx}>
                    <Typography sx={{ textDecoration: 'underline', color: ColorsHelper.orangeClr, fontWeight: '500', fontSize: '1.156rem', }}>
                        Terms & Conditions for New and Existing Customers
                    </Typography>

                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        // textJustify: 'inter-word',
                        mb: 3
                    }}>
                        THE FOLLOWING KEY POINTS (INCLUDING SECTION REFERENCES) ARE PROVIDED FOR YOUR CONVENIENCE ONLY AND ARE NOT IN LIEU OF THE FULL TERMS AND CONDITIONS. IT IS YOUR RESPONSIBILITY TO READ THOSE TERMS AND CONDITIONS BEFORE USING THE MENUONLINE INC SERVICE.
                    </Typography>


                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        Using <Box component={'span'} sx={{ color: ColorsHelper.orangeClr, textDecoration: 'underline' }}>Menuonline</Box>
                        &nbsp;means you agree to the Terms and Conditions and represent and warrant you’re authorized by your organization or company to do so. (See s.3 “How it works”)
                    </Typography>

                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        Menuonline Inc is a permission-based marketing service. Deliberate sending of “spam” or violating the Terms and Conditions will result in the closing of your account. (See s.16 “Acceptable Use of the Service”)
                    </Typography>

                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>

                        Data you host in Menuonline Inc such as contact records, email content and information you collect through Menuonline Inc belongs to you. (See s.14 “What do you own?”).
                    </Typography>

                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        The Menuonline Inc software and the content on our website belong to us. (See s.13 “What do we own?”)
                        Apart from your own users, the data or unpublished content you host with Menuonline Inc can be only be viewed by Menuonline Inc employees and partners who have signed confidentiality agreements. (See s.14 “What do you own?”)
                    </Typography>

                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        All Personally Identifiable Information (PII) is held only on servers located in Canada. (See s.14 “What do you own?”).
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        The Menuonline Inc service is subject to the laws of Canada and the province of Ontario. (See s.24 “Governing Law”)
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        Any requests for your data or content by individuals, police or other regulatory agencies from within Canada or outside of Canada will always be referred to you. The only exception would be if this puts us in violation of Canadian or Ontario law; however, in all cases we will strenuously resist any release of your data by anyone but you. (See s.14 “What do you own?”).
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        Menuonline Inc security is only as good as your management of usernames and passwords. We don’t charge for Menuonline Inc user accounts so create a separate one for each user and keep passwords secret. You are responsible for your account. (See s.3 “How it works”)
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        You can cancel your account at any time. We do not provide refunds on any unused credit if you cancel. (See s.3 “How it works”)
                    </Typography>

                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '600',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>
                        MENUONLINE INC TERMS AND CONDITIONS<br />
                        LAST UPDATE: January 14, 2020
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        Please read these terms and conditions of service (the “Terms“) carefully before using this web site (the “Site“). These Terms exempt Menuonline Inc. (“Menuonline Inc” “we“, or “us“) and others from liability and/or limit our and their liability and contain other important provisions that apply to your use of this Site.
                        By visiting or using this Site or the Service, you agree on your own behalf, and on behalf of any organization on whose behalf you may act (collectively referred to herein as “you“), to accept and abide by these Terms for each use of and each visit to this Site.
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '600',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>Our Services.
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        The services that we offer through the Site a service that allows you to create, send, and manage email messages (“Emails“) to recipients of your choosing (the “Service“).
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '600',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>License.
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        Subject to these Terms, Menuonline Inc hereby grants you a non-exclusive, limited, non-transferable license during the Term to view the Site and use the Service as permitted by the features of the Service. We reserve all rights not expressly granted herein in the Service and the Menuonline Inc Content (as defined below).
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '600',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>Emailing Content.
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        mb: 3
                    }}>
                        When interacting with Menuonline Inc via this Site or the Service, you agree to provide true and accurate information. Further you understand that providing confidential information via email is an unsafe practice. You understand that you are solely responsible for anything that you send to anyone using our Service.
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '600',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>Support.
                    </Typography>
                    <Typography sx={{
                        color: ColorsHelper.blackClr,
                        fontSize: '1.156rem',
                        fontWeight: '500',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}>
                        We provide you support to help you become self-sufficient with the Service. We provide you with support in the form of documentation and videos on the Site, the Menuonline Inc support ticket system and by telephone. You can reach us by clicking on the “Support” tab at the top of the Site. There’s no charge for product support; however, we reserve the right to discontinue support for specific client accounts at our discretion.
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default TermsNCondition