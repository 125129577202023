// Created By MK

// React Imports
import { useContext } from "react"

// MUI Imports
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// Components Imports
import { DialogContext } from "../../../Contexts"
import CustomBtn from "../../../CustomComponents/CutomButton"

// Style Imports
import landingStyle from "./landing.style"

const EasyToUse = () => {

    // Hooks
    const { setOpen } = useContext(DialogContext);

    return (
        <Grid container sx={landingStyle.etuMain} spacing={4}>
            <Grid item xs={12} md={6} sx={landingStyle.etuG1}>
                <Box>
                    <Typography sx={landingStyle.gspG2Txt1}>
                        EASY TO USE
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt2}>
                        Experience its user-friendly simplicity
                    </Typography>
                    <Typography sx={landingStyle.etuG1Txt1}>
                        No need for special devices: Easily accept incoming orders directly from your smartphone. With Menuonline app, you can noweffortlessly manage online food orders using your own device... with just a single tap.
                    </Typography>
                    <Box sx={landingStyle.etuG1Bx}>
                        <Box component={'img'} src='/images/download.svg' sx={{ mr: 1 }} />
                        <Typography sx={landingStyle.etuG1Txt2}>
                            Install our FREE order-tracking app for Android or iOS
                            on your smartphone, tablet or iPad to begin.
                        </Typography>
                    </Box>
                    <Box sx={landingStyle.cmnBtnBx}>
                        <CustomBtn
                            title='Get Started Free!'
                            sx={landingStyle.cmnBtn}
                            onClick={() => {
                                setOpen(true)
                            }} />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={landingStyle.etuG2}>
                <Box component={'img'} src='/images/userFriend.png' sx={landingStyle.etuG2Img} />
            </Grid>
        </Grid>
    )
}

export default EasyToUse