const ColorsHelper = {
    whiteClr: "#FFF",
    blackClr: "#000",
    blackClr1: "#192031",
    primary: "#85EE44",
    secondary: "#131313",
    lightClr: '#000000A6',
    lightClr2: '#F5F5F5',
    lightClr3: '#9B9B9C',
    lightClr4: '#D4D4D4',
    bgLight: 'red',
    grayClr: '#2D2D2D',
    lightBlack: "#1a1a1a",
    orangeClr: '#E57627',
    greenClr: '#006104',
};

export default ColorsHelper;
