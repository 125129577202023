import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import ColorsHelper from "../../Helpers/Colors"
import { useNavigate } from "react-router-dom"

const Footer = () => {
    const logos = ['facebook', 'twitter', 'linkedIn', 'insta']
    const footerBottom = [{ title: 'Copyright Menuonline@2024', url: '' }, { title: 'Terms of Service', url: '/termsOfService' }, { title: 'Privacy Policy', url: '/privacyPolicy' }]

    const navigate = useNavigate()

    return (
        <Box sx={{ px: { xs: 2, sm: 9.5 } }}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'start' }, mt: 2.2, mb: { xs: 0, sm: 2.6 } }} >
                <Box
                    onClick={() => {
                        navigate('/')
                    }}
                    component={'img'} src='/images/logoBlack.svg' sx={{ height: '2.5rem', mb: { xs: 2, sm: 0 }, cursor: 'pointer' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'start' }, mb: 4 }}>
                {
                    logos.map((item: string, i: number) => (
                        <Box key={i} component={'img'} src={`/images/${item}.svg`} sx={{ mr: 1, cursor: 'pointer' }} />
                    ))
                }
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {footerBottom.map((item: { title: string, url: string }, i: number) => (
                    <Typography
                        key={i}
                        sx={{
                            mx: { xs: i === 1 ? 1 : 0, sm: i === 1 ? 3 : 0 },
                            mb: 2,
                            color: ColorsHelper.lightClr3,
                            fontSize: { xs: '0.625rem', sm: '0.813rem' },
                            fontWeight: '400',
                            lineHeight: '1.313rem',
                            cursor: item.url !== '' ? 'pointer' : 'default'
                        }}
                        onClick={() => {
                            item.url && navigate(item.url);
                        }}
                    >
                        {item.title ?? ''}
                    </Typography>
                ))}
            </Box>
        </Box >
    )
}

export default Footer