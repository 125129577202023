const Consthelper = {
  emailRegex:
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i,
  mobileNoRegex: /^(?!0+$)[0-9\(\)-]{10,}$/,
  noRegex: /[^0-9]/g,
  mobileNRegex: /^(?!0+$)[0-9\(\)-]{9,}$/,
  spaceRegex: /^\s/,
  alphabetRegex: /[^ a-zA-Z]/g,
};

export default Consthelper;