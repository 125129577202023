// Craeted By MK
import ColorsHelper from "../../Helpers/Colors";

const headerStyle = {
  main: {
    backgroundColor: ColorsHelper.grayClr,
    boxShadow: "none",
    height: "4.438rem",
    display: "flex",
    justifyContent: "center",
    px: { xs: 0, sm: 6.4 },
  },
  logoImg: {
    mr: 1,
    display: { xs: "none", md: "flex" },
  },
  logoTxt: {
    mr: 2,
    display: { xs: "none", md: "flex" },
    color: ColorsHelper.blackClr,
    fontWeight: 700,
    textDecoration: "none",
  },
  logoTxtSM: {
    mr: 2,
    display: { xs: "flex", md: "none" },
    flexGrow: 1,
    fontWeight: 700,
    color: ColorsHelper.blackClr,
    textDecoration: "none",
  },
  logoImgSM: {
    mr: 1,
    display: { xs: "flex", md: "none" },
  },
  centerBx: {
    flexGrow: { xs: 0, md: 1 },
  },
};
export default headerStyle;
