// Created By MK
import ColorsHelper from "../../../Helpers/Colors";

const landingStyle = {
  // HeroSection
  heroMain: {
    bgcolor: ColorsHelper.lightBlack,
  },
  heroSubBx1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heroSubBx1Txt1: {
    pt: 22.5,
    textAlign: "center",
    fontSize: { xs: "2.25rem", md: "2.763rem" },
    fontWeight: "700",
    color: ColorsHelper.whiteClr,
    lineHeight: "3.623rem",
  },
  heroSubBx1Txt2: {
    mt: 2.2,
    textAlign: "center",
    fontSize: { xs: "1rem", md: "1.375rem" },
    fontWeight: "500",
    lineHeight: "1.8rem",
    color: ColorsHelper.whiteClr,
  },
  btnBx: { display: "flex", justifyContent: "center" },
  cusBtn: {
    mt: 2.9,
    padding: "0.5rem 1.125rem",
    fontSize: "0.938rem",
    fontWeight: "500",
  },
  heroSubBx1Txt3: {
    mt: 0.8,
    textAlign: "center",
    fontFamily: "Roobert, sans-serif !important",
    fontSize: "0.688rem",
    fontWeight: "100",
    color: ColorsHelper.whiteClr,
    lineHeight: "0.9rem",
  },
  heroSubBx2: { display: "flex", justifyContent: "center" },
  heroImg: {
    mt: 6,
    px: 2,
    width: { xs: "100%", md: "95%", lg: "96%" },
    height: "auto",
  },

  // GetStartedPage
  gspMain: { px: { xs: 2, sm: 9.5 }, py: 10 },
  gspG1: {
    order: { xs: 2, md: 1 },
    display: "flex",
    justifyContent: "center",
  },
  gspG1Img: { maxWidth: "100%", height: "auto" },
  gspG2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    order: { xs: 1, md: 2 },
    mb: { xs: 7, md: 0 },
  },
  gspG2Txt1: {
    color: ColorsHelper.orangeClr,
    fontWeight: "600",
    fontSize: { xs: "0.813rem", sm: "1.188rem" },
    mb: 1.2,
  },
  gspG2Txt2: {
    color: ColorsHelper.blackClr,
    fontWeight: "700",
    fontSize: { xs: "1.563rem", sm: "2.375rem" },
    lineHeight: { xs: "2.125rem", sm: "3.125rem" },
  },
  gspG2Txt3: {
    color: ColorsHelper.blackClr,
    fontWeight: "600",
    fontSize: { xs: "1.125rem", sm: "1.313rem" },
    my: 2,
  },
  gspG2Txt4: {
    color: ColorsHelper.blackClr,
    fontWeight: "400",
    fontSize: { xs: "0.813rem", sm: "1.063rem" },
  },
  gspG2Span: { color: ColorsHelper.greenClr, fontWeight: "600" },
  gspG2Txt5: {
    color: ColorsHelper.blackClr,
    fontWeight: "600",
    fontSize: { xs: "1.125rem", sm: "1.313rem" },
    my: 4,
  },
  cmnBtnBx: {
    display: "flex",
    justifyContent: { xs: "center", md: "start" },
  },
  cmnBtn: {
    padding: "0.5rem 1.125rem",
    fontSize: "0.938rem",
    fontWeight: "500",
    cursor: "pointer",
  },

  // EasyToUse
  etuMain: { px: { xs: 2, sm: 9.5 }, py: 10, bgcolor: ColorsHelper.lightClr2 },
  etuG1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mb: { xs: 7, md: 0 },
  },
  etuG1Txt1: {
    color: ColorsHelper.blackClr,
    fontWeight: "400",
    fontSize: { xs: "0.938rem", sm: "1.063rem" },
    lineHeight: { xs: "1.375rem", sm: "1.5rem" },
    my: 2,
  },
  etuG1Bx: {
    display: "flex",
    mb: 4,
  },
  etuG1Txt2: {
    color: ColorsHelper.blackClr,
    fontWeight: "400",
    fontSize: { xs: "0.938rem", sm: "1.063rem" },
    lineHeight: { xs: "1.375rem", sm: "1.5rem" },
  },
  etuG2: { display: "flex", justifyContent: "center" },
  etuG2Img: { maxWidth: "100%", height: "auto" },

  // FreeTools
  ftMain: { px: { xs: 2, sm: 9.5 }, py: 10 },
  ftG1: {
    order: { xs: 2, md: 1 },
    display: "flex",
    justifyContent: { xs: "center", md: "start" },
  },
  ftG1Img: {
    // maxWidth: '100%',
    width: "100%",
    height: "auto",
  },
  ftG2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    order: { xs: 1, md: 2 },
    mb: { xs: 7, md: 0 },
  },
  ftG2Txt1: {
    color: ColorsHelper.blackClr,
    fontWeight: "400",
    fontSize: { xs: "0.938rem", sm: "1.063rem" },
    lineHeight: { xs: "1.375rem", sm: "1.5rem" },
    my: 2,
  },
  ftG2Bx1: { display: "flex", alignItems: "center", mb: 2 },
  ftG2CheckBx: { mr: 2, height: "1.25rem" },
  ftG2CheckTxt: {
    color: ColorsHelper.blackClr,
    fontWeight: "400",
    fontSize: { xs: "0.938rem", sm: "1.063rem" },
    lineHeight: "1.5rem",
  },

  //   SinglePlatform
  spMain: { px: { xs: 2, sm: 9.5 }, py: 10, bgcolor: ColorsHelper.lightClr2 },
  spG1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mb: { xs: 7, md: 0 },
  },
  spG1Txt1: {
    color: ColorsHelper.orangeClr,
    fontWeight: "600",
    fontSize: { xs: "0.813rem", sm: "1.188rem" },
    mb: 1.2,
  },
  spG1Txt2: {
    color: ColorsHelper.blackClr,
    fontWeight: "700",
    fontSize: { xs: "1.563rem", sm: "2.375rem" },
    lineHeight: { xs: "2.125rem", sm: "3.125rem" },
  },
  spG1Txt3: {
    color: ColorsHelper.blackClr,
    fontWeight: "400",
    fontSize: "1.063rem",
    lineHeight: "1.5rem",
    my: 2,
  },
  spG2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  spG2Img: {
    mt: { xs: 6, md: 0 },
    maxWidth: "100%",
    height: "auto",
    zIndex: 1,
  },
  spArrow: {
    position: "absolute",
    bottom: "6%",
    left: "25%",
    zIndex: 0,
    display: { xs: "none", md: "block" },
  },
};
export default landingStyle;
