// Created By MK

// MUI Imports
import Box from '@mui/material/Box';

// Third Party Imports
import { Helmet } from 'react-helmet-async';

// Components Imports
import HeroSection from './Landing/HeroSection';
import GetStartedPage from './Landing/GetStartedPage';
import EasyToUse from './Landing/EasyToUse';
import FreeTools from './Landing/FreeTools';
import SinglePlatform from './Landing/SinglePlatform';

const Homepage = () => {

    return (
        <Box>
            <Helmet>
                <title>Home | Menu Online</title>
                <meta name="description" content="Menu Online  Home" />
            </Helmet>
            <HeroSection />
            <GetStartedPage />
            <EasyToUse />
            <FreeTools />
            <SinglePlatform />
        </Box>
    )
}

export default Homepage